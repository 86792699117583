import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Link,
  useBreakpointValue,
  useDisclosure,
  Text,
  Spacer
} from '@chakra-ui/react'
import * as React from 'react'
import { FiHelpCircle, FiSearch, FiSettings } from 'react-icons/fi'
import { Logo } from './Logo'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'
import { ColorModeSwitcher } from './ColorModeSwitcher';

const startOver = () => {
  localStorage.setItem("appState", "state0");
  localStorage.setItem("topic", "");
  localStorage.setItem("subtopic1", "");
  localStorage.setItem("subtopic2", "");
  localStorage.setItem("title", "");
  localStorage.setItem("outline", "");
  localStorage.setItem("displayText", JSON.stringify([]));
  localStorage.setItem("displayTextPre", JSON.stringify([]));
  window.location.href='/';
};


export const Navbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const { isOpen, onToggle, onClose } = useDisclosure()
  

  return (
    <Box as="nav" bg="bg-accent" color="on-accent">
      <Container
        py={{
          base: '3',
          lg: '4',
        }}
      >
        <Flex justify="space-between">
          <HStack spacing="4">
            {/* <Logo />  */}
            {isDesktop ? (
            <Center><Text as="b" fontSize='3xl' ml={{base:'2', sm:'8',  lg: '12'}} onClick={() => {startOver()}}>Muse ✨</Text></Center>
            ) :
            <Center><Text as="b" fontSize='4xl' ml={{base:'4', sm:'4', md:'8', lg: '8'}} onClick={() => {startOver()}}>Muse ✨</Text></Center>
            }
            {isDesktop && (

              <ButtonGroup variant="ghost-on-accent" spacing="1">

                <Button aria-current="page" onClick={() => {startOver()}}>Home</Button>
                {/* <Button>My Lyst</Button>*/}
                {/* <Button>About</Button>  */}

              </ButtonGroup>
            )}
          </HStack>
          {isDesktop ? (
            <HStack spacing="4" mr={12}>
              <ButtonGroup variant="ghost-on-accent" spacing="1">
                <Button as={Link} href="https://linktr.ee/camarmstrong" target="_blank" aria-current="page">Contact Us</Button>
                {/* <IconButton icon={<FiSearch fontSize="1.25rem" />} aria-label="Search" />
                <IconButton icon={<FiSettings fontSize="1.25rem" />} aria-label="Settings" />
                <IconButton icon={<FiHelpCircle fontSize="1.25rem" />} aria-label="Help Center" /> */}
                <ColorModeSwitcher justifySelf="flex-end" />
                <Avatar as={Link} href="https://linktr.ee/camarmstrong" target="_blank" boxSize="10" name="Cameron" src="https://i.imgur.com/O1SS2q2.png" />
              </ButtonGroup>
              </HStack>
          ) : (
            
            <Center><HStack>
              <ColorModeSwitcher justifySelf="flex-end" />
              <Avatar mr={{base:'4',sm:'8' }} as={Link} href="https://linktr.ee/camarmstrong" target="_blank" boxSize="10" name="Cameron" src="https://i.imgur.com/O1SS2q2.png" />
              </HStack>
              </Center>
 
 
            // <>
            //   <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
            //   <Drawer
            //     isOpen={isOpen}
            //     placement="left"
            //     onClose={onClose}
            //     isFullHeight
            //     preserveScrollBarGap
            //     // Only disabled for showcase
            //     trapFocus={false}
            //   >
            //     <DrawerOverlay />
            //     <DrawerContent>
            //       <Sidebar />
            //     </DrawerContent>
            //   </Drawer>
            // </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}
