import { BsFillLightningFill, BsStar } from 'react-icons/bs'
import { FaAccessibleIcon, FaExpandAlt, FaPaintBrush } from 'react-icons/fa'
import { IoRocketSharp } from 'react-icons/io5'

export const features = [
  {
    name: 'Content Analysis',
    description: 'Muse analyzes comments and content from your channel, your niche, and across your platform to surface the topics people want to see',
    icon: BsStar,
  },
  {
    name: '100+ Data Sources',
    description:
      'Our calculation engine uses 100+ additional data sources to expand the content idea selection pool',
    icon: IoRocketSharp,
  },
  {
    name: 'AI Powered Topic Synthesis',
    description: 'Muse uses proprietary AI models to synthesize, score, and prioritize the best content ideas for your channel',
    icon: BsFillLightningFill,
  },
//   {
//     name: 'Themeable',
//     description:
//       "Your style. Your blue. Customize the components as you need them. It's that simple.",
//     icon: FaPaintBrush,
//   },
//   {
//     name: 'Fully Responsive',
//     description: 'Responsive components that look great on mobile, tablet and desktop.',
//     icon: FaExpandAlt,
//   },
//   {
//     name: 'Accessible',
//     description:
//       "Accessibility first. That's why we pay attention to accessibility right from the start.",
//     icon: FaAccessibleIcon,
//   },
]