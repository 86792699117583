import * as React from 'react';
import { useState, useEffect } from "react";
import {
  ChakraProvider,
  Avatar,
  Square,
  Box,
  Center,
  Container,
  Button,
  Link,
  VStack,
  Code,
  Grid,
  GridItem,
  extendTheme,
  Flex,
  Image,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
  filter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
  useDisclosure,
  Tag,
  Spinner,
  Stack,
  useToast,
  CloseButton,
  Spacer,
  useBreakpointValue,
  ListItem, UnorderedList
} from '@chakra-ui/react';
import { Navbar } from './Navbar'
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from 'react-router-dom';
import {
  MasterComponent,
} from "./components";
import emailjs from '@emailjs/browser';
import { FaTwitter,FaGithub, FaLinkedin } from 'react-icons/fa'
import { features } from './data'
import { members } from './teamdata'
import { Select, AsyncSelect } from "chakra-react-select";
import { groupedOptions, colorOptions, groupedCountries } from "./docs/data";
import LogRocket from 'logrocket';
// import {CENTER_KEY} from './constant';
LogRocket.init('y3fljr/muse-t6dnd');
// LogRocket.init('y3fljr/wishlyst');
// import { Configuration, OpenAIApi } from "openai";
// const configuration = new Configuration({
//     organization: "org-71D66NwPZZoxrq74vcl898CW",
//     apiKey: process.env.OPENAI_API_KEY,
// });
// const openai = new OpenAIApi(configuration);
// const response = await openai.listEngines();



export const theme = extendTheme(
  {initialColorMode: 'system',
    colors: { ...proTheme.colors, brand: proTheme.colors.purple },
    components: {
      Popover: {
        variants: {
          responsive: {
            popper: {
              maxWidth: 'unset',
              width: 'unset'
            }
          }
        }
      }
    }
  },
  proTheme,
)




function App() {

  const [callState, setCallState] = useState("");
  
  // const [stateLock, setStateLock] = useState(false);

  // Instantiate Email Capture Variable
  const [emailCapture, setEmailCapture] = useState("");
  
  var emailParams = {
    reply_to: emailCapture,
    from_name: "Muse",
  }

  function sendEmail() {
    //Send email to Client about Saved NFT
        emailjs.send("service_tprcqsf", "muse_welcome", emailParams, "01KJO-QDJo4-Wm8z8")
          .then((result) => {
              // console.log(result.text);
              toast({
                title: `You're signed up! We'll reach out shortly to help you set up your account.`,
                status: 'success',
                isClosable: true,
              })
          }, (error) => {
              console.log(error.text);
          });
      };
 

      // Email Capture Modal functionality  

    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
//success toast
const toast = useToast()   

// Increment Star and capture email for NFT saving
function plusOne() {
  if (emailCapture==="" || !emailCapture.includes("@")) {
    // console.log("CANT DO IT")
    toast({
      title: `Please check the email address you entered and try again.`,
      status:  'error',
      isClosable: true,
    });
    return
  }
  else {
    sendEmail();
  }
  // console.log("emailCapture", emailCapture);
}

function saveEmail(e) {
  setEmailCapture(e.target.value);
}

const handleKeyDownEmail = (event) => {
  if (event.key === "Enter") {
    event.preventDefault(); // prevent default behavior (e.g. page refresh)
    plusOne();
  }
};



// AI Component
const OpenAI = require('openai');
const { Configuration, OpenAIApi } = OpenAI

const [inputText, setInputText] = useState("");
const [temperature, setTemperature] = useState(0.7);
const configuration = new Configuration({
  organization: "org-71D66NwPZZoxrq74vcl898CW",
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);

// App State Variables
useEffect(() => {
  if (localStorage.getItem('appState') !== null) {
    var appState =localStorage.getItem('appState');
  } else {
    var appState = "state0"
  }
  console.log("appState useeffect", appState)

}, []);

try {
var displayText = JSON.parse(localStorage.getItem("displayText"));
} catch (e) {
var displayText = []};
if (displayText === null) {
  displayText = [];
}

const [textArray, setTextArray] = useState([]);
const [buttonText, setButtonText] = useState("Get Inspired ✨");
// var topic = "Gaming";
var topic = localStorage.getItem("topic");
var subtopic1 = localStorage.getItem("subtopic1");
var subtopic2 = localStorage.getItem("subtopic2");
var title = localStorage.getItem("title");
var outline = "1 Introduction 2 How to be a pro gamer 3 Conclusion Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."

const options = [
  "Gaming",
  "Blockchain",
  "Fashion",
  "Technology",
  "Cooking",
  "Travel",
  "Adventure",
  "Fitness",
  "Health",
];
//   "Music",
//   "Comedy",
//   "Humor",
//   "Education",
//   "Tutorials",
//   "Art",
//   "Design",
//   "Beauty",
//   "Food",
//   "Technology",
//   "Gadgets",
//   "DIY",
//   "Crafting",
//   "Personal development",
//   "Motivation",
//   "Parenting",
//   "Family",
//   "Sports",
//   "Athletics",
//   "Science",
//   "Innovation",
//   "Lifestyle",
//   "Culture",
//   "Pets",
//   "Animals",
//   "Finance",
//   "Money management",
//   "Politics",
//   "Activism",
//   "Entertainment",
//   "Media review"
// ];






//Prompts
const prompt0 = "Act as a researcher and generate 1 subtopic, 1 important concept, 1 controversial idea, 1 closely related topic, and 1 distantly related topic to the subject "
const prompt00 = ", do not repeat the input prompt, and render the output as follows: \
Subtopic 1 - 'Answer'\
Subtopic 2 - 'Answer'\
Subtopic 3 - 'Answer'\
Related Subtopic - 'Answer'\
Related Subtopic - 'Answer'\
Do not add context to the answers, just the answers."
const ideaPrompt1 = "Act as a substack writer and generate 1 popular content idea, 1 thoughtful content idea, 1 very specific content idea, 1 popular content idea on a closely related topic, and 1 popular content idea on a distantly related topic to the subject "
const ideaPrompt2 = " without labelling them and render output as follows: \
'Answer1 -' \
'Answer2 -' \
'Answer3 -' \
'Answer4 -' \
'Answer5 -' \
Do not add context to the answers, just the answers."

const outlinePrompt = "Write in the style of a professional essayist, avoid using commas, and generate a 3 part essay outline with paragraph headers and sub-paragraphs on the following subject "

//Continue Prompts
const continuePrompt = "Write in the style of a professional essayist and completely finish the remainder of this essay outline "

// Chat GPT Call
var callOpenAI = async (inputText, prompt1, prompt2, flag) => {
  // const response1 = await openai.listEngines();
  // console.log(response1.data);
  console.log("inputText",inputText)
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(inputText)
  };
  const fullPrompt = prompt1 + inputText + prompt2;

  const { message } = inputText;
  const response = await openai.createChatCompletion({
      "model": "gpt-4",
      messages: [{role: "user", content: fullPrompt}],
      
      "max_tokens": 250,
      // "temperature": .99, //change back to variable later,
      // "frequency_penalty": .2,
      // "presence_penalty": .2,
});

// GPT 3 Davinci Call
// var callOpenAI = async (inputText, prompt1, prompt2, flag) => {
//   // const response1 = await openai.listEngines();
//   // console.log(response1.data);
//   console.log("inputText",inputText)
//   const options = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(inputText)
//   };
//   const fullPrompt = prompt1 + inputText + prompt2;

//   const { message } = inputText;
//   const response = await openai.createCompletion({
//       "model": "text-davinci-003",
//       "prompt": fullPrompt,
//       "max_tokens": 250,
//       "temperature": .99, //change back to variable later,
//       "frequency_penalty": .2,
//       "presence_penalty": .2,
// });


// Make sure to split the text by new line and filter out empty strings
console.log("response",response.data.choices[0].message.content)
console.log("responseFull",response);
const choicesText = response.data.choices[0].message.content;
// const textArray = choicesText;
const textArray = choicesText.split('\n').filter(text => text.trim() !== '');

console.log("textArray",textArray)

handleDisplayText(textArray,flag); //stores displayText for next screen



setCallState("done"); //goes to next screen

};


  
// Make typing for Input autosubmit on enter    
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // prevent default behavior (e.g. page refresh)
      handleOutlineButtonClick(inputText);
    }
  };

// Set the input text after converting the synthetic event to a string
const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

// State 0 Submit Button - Both Top and Bottom
const handleButtonClick = (buttonText) => {
  setCallState("loading");
    
    if (temperature < 1) {
      var newTemp = (temperature + 0.05) 
      setTemperature(newTemp);
    } else {
      setTemperature(0.7);
    }

    if (buttonText) {
      callOpenAI(buttonText, prompt0, prompt00);
      handleTopicSelect(buttonText);
    
    } else {
      console.log("inputText",inputText)
      callOpenAI(inputText, prompt0, prompt00);
      handleTopicSelect(inputText);
      
    }
    // console.log("temperature", temperature)
    // setInputText("");
  };

// State 0 Function to Store Selected Topic in localstorage for later retrieval
const handleTopicSelect = (topic) => {
  localStorage.setItem("topic", topic);
  localStorage.setItem("appState", "state1");
};

// All State Function to store api response in localstorage for later retrieval and display
const handleDisplayText = (displayText,flag) => {
  localStorage.setItem("displayText", JSON.stringify(displayText));
  
  if (flag === true) {
    goState3();
  } else {

    if (localStorage.getItem("appState")==="state1") {
    goState1();
    } else if (localStorage.getItem("appState")==="state2") {
      goState2();
    } else if (localStorage.getItem("appState")==="state3") {
      goState3();
    } else if (localStorage.getItem("appState")==="state4") {
      goState4();
    };
  }
};



// State 1 Choose Button
const handleDeepButtonClick = (text) => {
  // console.log(text);
  setCallState("loading");
  
    
  if (temperature < 1) {
    var newTemp = (temperature + 0.05) 
    setTemperature(newTemp);
  } else {
    setTemperature(0.7);
  }
    // var topicSubtopic = localStorage.getItem("topic") + " " + text;
    callOpenAI(text, prompt0, prompt00);
    handleSubtopic1(text);
    // console.log("temperature", temperature)
    // setInputText("");
  };

// State 1 Function to store api response in localstorage for later retrieval
const handleSubtopic1 = (subtopic) => {
  const filteredSubtopic = subtopic.split("- ")[1];
  // console.log("filteredSubtopic",filteredSubtopic, filteredSubtopic.toString())
  if (subtopic1) {
    try {
      localStorage.setItem("subtopic2", filteredSubtopic.toString());
    } catch (error) {
      console.log("error",error)
      localStorage.setItem("subtopic2", filteredSubtopic);
    }
    localStorage.setItem("appState", "state3");
    handleIdeaButtonClick();
  } else {
  try{
    localStorage.setItem("subtopic1", filteredSubtopic.toString());
  } catch (error) {
    console.log("error",error)
    localStorage.setItem("subtopic1", filteredSubtopic);
  }
  localStorage.setItem("appState", "state2");
  };
  
};

// All States Generate Ideas Button
const handleIdeaButtonClick = () => {
  setCallState("loading");
  var topicSubtopic = localStorage.getItem("topic") + " " + localStorage.getItem("subtopic1") + " " + localStorage.getItem("subtopic2");
  console.log("topicSubtopic",topicSubtopic,ideaPrompt1,ideaPrompt2)
  callOpenAI(topicSubtopic, ideaPrompt1, ideaPrompt2, true);
};

// State 3 Generate Outline Button
const handleOutlineButtonClick = (e) => {
  setCallState("loading");
  localStorage.setItem("appState", "state4");
  localStorage.setItem("title", e);
  callOpenAI(e, outlinePrompt);
};

const continueText = (e) => {
  setCallState("loading");
  localStorage.setItem("appState", "state4");
  localStorage.setItem("displayTextPre", displayText);
  var tempDisplay = displayText;
  localStorage.setItem("displayText", JSON.stringify([]));
  callOpenAI(continuePrompt, tempDisplay);
};


const exportTextButton = () => {
  const element = document.createElement('a');
    const file = new Blob([displayText.join('\n')], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `${title}.txt`;
    document.body.appendChild(element);
    element.click();
  };
  



// Function List to handle switching windows when state changes occur
const goState1 = () => {
  
  window.location.href='/topic';
};

const goState2 = () => {
  window.location.href='/subtopic';
};

const goState3 = () => {
  window.location.href='/content';
};

const goState4 = () => {
  window.location.href='/outline';
};

const startOver = () => {
  localStorage.setItem("appState", "state0");
  localStorage.setItem("topic", "");
  localStorage.setItem("subtopic1", "");
  localStorage.setItem("subtopic2", "");
  localStorage.setItem("title", "");
  localStorage.setItem("outline", "");
  localStorage.setItem("displayText", JSON.stringify([]));
  localStorage.setItem("displayTextPre", JSON.stringify([]));
  window.location.href='/';
};

// App State Management
// if (appState === "state0") {
//     console.log("appState should be 0",appState)
//     // setAppState(localStorage.getItem("appState"));
//     // This is the "ready" state of the app
//     // The app will render the "state0" UI
//     // User will click a topic to move to state 1
//   } else if (appState === "state1" & window.location.href!='/topic') {
//     // This is the state where a Topic has been selected
//     // and the app renders the "state1" UI of Subtopics to choose
//     goState1()
//   } 
//   else if (appState === "state1") {
    // console.log("state1")

    // User will click a subtopic to move to state 2
  // }
  // else if (appState === "state2") {
    // This is the state where a Subtopic has been selected
    // Users can choose to generate ideas or pick one additional subtopic
  // } else if (appState === "state3") {
    // This is the state where Content Ideas are generated
    // Either with 1 or 2 Subtopics have been selected
    // Users may render an outline or generate more ideas
  // } else if (appState === "state4") {
    // This is the state where an Outline is generated
    // Users may export the outline or Start Over
  // }



    const parseOutline = () => {
      if (localStorage.getItem('displayTextPre') !== null) {
      if (localStorage.getItem('displayTextPre') !== "[]") {
        
        var test = localStorage.getItem('displayTextPre');

        var test3 = displayText.toString()

        var test2 = displayText.toString().split(',').filter(item => item !== '');

        const combinedInput = test.split(',').concat(test2);

// console.log("combinedInput",combinedInput)
          return combinedInput.map((item) => {
            const indentation = item.search(/\S/);
            return { level: indentation / 4, text: item.trim() };
          });

      }
    }
      return displayText.map((item) => {
        const indentation = item.search(/\S/);
        return { level: indentation / 4, text: item.trim() };
      });
    };
  
    const essayOutline = parseOutline();

    const renderOutline = () => {
      return essayOutline.map((item, index) => (
        <ListItem key={index} ml={`${item.level * 20}px`}>
          {item.text}
        </ListItem>
      ));
    };


  return (
<ChakraProvider theme={theme} >
  <Router>

 {/* App Start */}
 <Box as="section" height="100%" overflowY="auto" bg="bg-surface">
    
    {/* Navbar is by itself */}
    <Navbar />
    <Switch>
    <Route exact path="/">
    <Box bg="bg-surface" height="100%" overflowY="auto">
    <Container py={{ base: '0', md: '0' }}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '12', lg: '16' }}>
     
        <Center>

  
      </Center>
      </Stack>
      <Flex direction="column" alignItems="center" justifyContent="center"  spacing="5">
      <Center><Text as='b' fontSize='4xl' width={{base:"100%"}} mt="16px">Beat Writer's Block 🫡</Text></Center>
      <Center><Text fontSize='3xl' width={{base:"100%"}} mt="12px">Just enter a topic!</Text></Center>
      {/* <Center><Text fontSize='2xl' width={{base:"100%"}}>2. Select a Subtopic (or Two 😉)</Text></Center>
      <Center><Text fontSize='2xl' width={{base:"100%"}}>3. Choose an Idea from the List</Text></Center> */}
      <Center><Text fontSize='3xl' width={{base:"100%"}} mt="12px">Muse will create an essay outline (It might take a second...)</Text></Center>
      {/* <Center><Text fontSize='3xl' width={{base:"100%"}} >an essay outline ASAP</Text></Center> */}

      </Flex>
      <Flex direction={{base:"column",md:"row"}} alignItems="center" justifyContent="center" mt="12px" spacing="5">
      
      <Center>
      <Input border="2px solid gray" height="60px" backgroundColor="white" color="black" value={inputText} onChange={handleInputChange} onKeyDown={handleKeyDown} placeholder="Type in a Topic..." _placeholder={{ opacity: 1, color: 'gray.500' }} width={{ base: "100%", lg: '500px' }} mb="5" mt='5' />
      </Center>
      {/* <Center>
      <Button onClick={()=>handleButtonClick(inputText)} mx="2" height="60px">
        <Text as='b' fontSize='xl'>Explore Subtopics</Text>
      </Button>
      </Center> */}
      <Center>
      <Button onClick={()=>handleOutlineButtonClick(inputText)}  mx="2" height="60px" mt={{base:"10px",md:"0"}}>
        <Text as='b' fontSize='xl'>Get Outline✨</Text>
      </Button>
      </Center>
    </Flex>
    {(callState === "loading") ? <Center mb='10' mt='10'><Spinner size='xl'/></Center>: null}
    {/* <Flex direction="column" alignItems="center" justifyContent="center"  spacing="5">
    <Center><Text fontSize='2xl' width={{base:"100%"}} mt="12px">Or choose a Topic below</Text></Center>
    <Container py={{ base: '0', md: '0' }} mt="12px">
    <Center>
    <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3,
          }}
          columnGap={4}
          rowGap={{
            base: 4,
            md: 8,
          }}
          mt="12px"
        >
          {options.map((option) => (
            <Center>
            <GridItem key={option}>
              <Button onClick={(e) => handleButtonClick(e.target.innerText)} mx="2" height="70px" width={{base:"220px",md:"140px"}}><Text fontSize="xl">{option}</Text></Button>
            </GridItem>
            </Center>
          ))}
        </SimpleGrid>
        </Center>
        </Container>
        </Flex> */}
        <Center>
     <Stack width={{ base: "100%", lg: '500px' }} mt='60px' direction={{base:"column",md:"row"}} >
          <FormControl>
                <Input border="2px solid gray" backgroundColor="white" color="black" variant='outlined' size='lg' ref={initialRef} onKeyDown={handleKeyDownEmail} placeholder='MrBeast@gmail.com' _placeholder={{ opacity: 1, color: 'gray.500' }} onChange={saveEmail}/>
          </FormControl>
            
            
          <Button size="lg" colorScheme="purple" type="submit" onClick={plusOne}>
            Get Early Access
          </Button>
          </Stack>
          </Center>

          <Center mt="12px" >
            <VStack spacing={4} mb="12px">
            <Link href="https://t.me/camarmstr" target="_blank"><Button>Share Feedback</Button></Link>
              <MasterComponent/>
              
            </VStack>
          </Center>
    </Container>
    </Box>
    </Route>


    {/* Topic Page */}
    <Route exact path="/topic">
    <Box bg="bg-surface" height="100%" overflowY="auto">
    <Container py={{ base: '0', md: '0' }}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '12', lg: '16' }}>
     
        <Center>

  
</Center>
      </Stack>
      <Flex direction="column" alignItems="center" justifyContent="center"  spacing="5">
      <Center><Text fontSize='4xl' as='b'  width={{base:"100%"}} mt='16px' mb='8px'>Topic - {topic}</Text></Center>
      <Center><Text fontSize='xl' as='b'  width={{base:"100%"}} mt='8px' mb='32px'>Select a Subtopic!</Text></Center>
    </Flex>
    {(callState === "loading") ? <Center mb='10'><Spinner size='xl'/></Center>: null}
    
    <Center>

    <Box width={{ base: "100%", md: "70%" }} >

      {displayText.map((text, index) => (
        <Box key={index} borderWidth="1px" borderRadius="lg" p="4" mb="4">
          <Flex direction={{base:"column",md:"row"}} >
            <Center>
          <Text as='b' fontSize="2xl">{text}</Text>
          </Center>
          <Spacer />

<Center>
        <Button onClick={e=>{handleDeepButtonClick(text)}} mt={{base:"8px",md:"0"}} width={{base:"100%",md:"100%"}}>
            <Text fontSize="xl" as='b'>Select</Text>
          </Button>
          </Center>          
          </Flex>
        </Box>
      ))}
    </Box>
    </Center>

    <Center>
<Stack   direction={{base:"column",md:"row"}} spacing="6">
    <Center>
      
    <Button onClick={()=>{startOver()}} height="60px"  mx="2">
        <Text fontSize="xl">Start Over</Text>
      </Button>
      </Center><Center>
    <Button onClick={()=>{handleButtonClick(topic)}} height="60px"  mx="2">
        <Text fontSize="xl">Gimme More Subtopics</Text>
      </Button>
      </Center>
        <Center>
    <Button onClick={()=>{handleIdeaButtonClick()}} height="60px"  mx="2" border="1px solid #B794F4">
        <Text fontSize="xl" >Generate Ideas ✨</Text>
      </Button>
      </Center>
      </Stack>
      </Center>
     <Center>
      
     <Stack width={{ base: "100%", md: "70%" }} mt='30px' direction={{base:"column",md:"row"}} >
     
          <FormControl>
                <Input border="2px solid gray" backgroundColor="white" color="black" variant='outlined' size='lg' ref={initialRef} onKeyDown={handleKeyDownEmail} placeholder='MrBeast@gmail.com' _placeholder={{ opacity: 1, color: 'gray.500' }} onChange={saveEmail}/>
          </FormControl>
            
            
          <Button size="lg" colorScheme="purple" type="submit" onClick={plusOne}>
            Get Early Access
          </Button>
          </Stack>
          </Center>

          <Center mt="12px" >
            <VStack spacing={4} mb="12px">
            <Link href="https://t.me/camarmstr" target="_blank"><Button>Share Feedback</Button></Link>
              <MasterComponent/>
              
            </VStack>
          </Center>
    </Container>
    </Box>

      </Route>
    




    <Route exact path="/subtopic">
    <Box bg="bg-surface" height="100%" overflowY="auto">
    <Container py={{ base: '0', md: '0' }}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '12', lg: '16' }}>
     
        <Center>

  
</Center>
      </Stack>
      <Flex direction="column" alignItems="center" justifyContent="center"  spacing="5">
      <Center><Text fontSize='4xl' as='b'  width={{base:"100%"}} mt='16px' mb='8px'>Topic - {topic}</Text></Center>
      <Center><Text fontSize='3xl' as='b'  width={{base:"100%"}} mt='16px' mb='8px'>Subtopic 1 - {subtopic1}</Text></Center>
      <Center><Text fontSize='xl' as='b'  width={{base:"100%"}} mt='8px' mb='32px'>Select another Subtopic!</Text></Center>
    </Flex>
    {(callState === "loading") ? <Center mb='10'><Spinner size='xl'/></Center>: null}
    
    <Center>

    <Box width={{ base: "100%", md: "70%" }} >

      {displayText.map((text, index) => (
        <Box key={index} borderWidth="1px" borderRadius="lg" p="4" mb="4">
          <Flex direction={{base:"column",md:"row"}} >
            <Center>
          <Text as='b' fontSize="2xl">{text}</Text>
          </Center>
          <Spacer />

<Center>
        <Button onClick={e=>{handleSubtopic1(text)}} mt={{base:"8px",md:"0"}} width={{base:"100%",md:"100%"}}>
            <Text fontSize="xl" as='b'>Select</Text>
          </Button>
          </Center>          
          </Flex>
        </Box>
      ))}
    </Box>
    </Center>

    <Center>
<Stack   direction={{base:"column",md:"row"}} spacing="6">
    <Center>
      
    <Button onClick={()=>{startOver()}} height="60px"  mx="2">
        <Text fontSize="xl">Start Over</Text>
      </Button>
      </Center><Center>
    <Button onClick={()=>{handleDeepButtonClick(topic,subtopic1)}} height="60px"  mx="2">
        <Text fontSize="xl">Gimme More Subtopics</Text>
      </Button>
      </Center>
        <Center>
    <Button onClick={()=>{handleIdeaButtonClick()}} height="60px"  mx="2" border="1px solid #B794F4">
        <Text fontSize="xl">Generate Ideas ✨</Text>
      </Button>
      </Center>
      </Stack>
      </Center>
     <Center>
      
     <Stack width={{ base: "100%", md: "70%" }} mt='30px' direction={{base:"column",md:"row"}} >
     
          <FormControl>
                <Input border="2px solid gray" backgroundColor="white" color="black" variant='outlined' size='lg' ref={initialRef} onKeyDown={handleKeyDownEmail} placeholder='MrBeast@gmail.com' _placeholder={{ opacity: 1, color: 'gray.500' }} onChange={saveEmail}/>
          </FormControl>
            
            
          <Button size="lg" colorScheme="purple" type="submit" onClick={plusOne}>
            Get Early Access
          </Button>
          </Stack>
          </Center>

          <Center mt="12px" >
            <VStack spacing={4} mb="12px">
            <Link href="https://t.me/camarmstr" target="_blank"><Button>Share Feedback</Button></Link>
              <MasterComponent/>
              
            </VStack>
          </Center>
    </Container>
    </Box>
    </Route>


    <Route exact path="/content">
    <Box bg="bg-surface" height="100%" overflowY="auto">
    <Container py={{ base: '0', md: '0' }}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '12', lg: '16' }}>
     
        <Center>

  
</Center>
      </Stack>
      <Flex direction="column" alignItems="center" justifyContent="center"  spacing="5">
      <Center><Text fontSize='4xl' as='b'  width={{base:"100%"}} mt='16px' mb='8px'>Topic - {topic}</Text></Center>
      <Center><Text fontSize='2xl' as='b'  width={{base:"100%"}}  mb='8px'>Subtopic 1 - {subtopic1}</Text></Center>
      <Center><Text fontSize='2xl' as='b'  width={{base:"100%"}}  mb='8px'>Subtopic 2 - {subtopic2}</Text></Center>
      <Center><Text fontSize='xl' as='b'  width={{base:"100%"}} mt='8px' mb='32px'>Choose an idea!</Text></Center>
    </Flex>
    {(callState === "loading") ? <Center mb='10'><Spinner size='xl'/></Center>: null}
    
    <Center>

    <Box width={{ base: "100%", md: "70%" }} >

      {displayText.map((text, index) => (
        <Box key={index} borderWidth="1px" borderRadius="lg" p="4" mb="4">
          <Flex direction={{base:"column",md:"row"}} >
            <Center>
          <Text as='b' fontSize="2xl">{text.split("- ")[1]}</Text>
          </Center>
          <Spacer />

<Center>
        <Button onClick={e=>{handleOutlineButtonClick(text.split("- ")[1])}} mt={{base:"8px",md:"0"}} width={{base:"100%",md:"100%"}}>
            <Text fontSize="xl" as='b'>Choose ✨</Text>
          </Button>
          </Center>          
          </Flex>
        </Box>
      ))}
    </Box>
    </Center>

    <Center>
<Stack   direction={{base:"column",md:"row"}} spacing="6">
    <Center>
      
    <Button onClick={()=>{startOver()}} height="60px"  mx="2">
        <Text fontSize="xl">Start Over</Text>
      </Button>
      </Center><Center>
    <Button onClick={()=>{handleIdeaButtonClick()}} height="60px"  mx="2">
        <Text fontSize="xl">Gimme More Ideas</Text>
      </Button>
      </Center>
        <Center>
      </Center>
      </Stack>
      </Center>
     <Center>
      
     <Stack width={{ base: "100%", md: "70%" }} mt='30px' direction={{base:"column",md:"row"}} >
     
          <FormControl>
                <Input border="2px solid gray" backgroundColor="white" color="black" variant='outlined' size='lg' ref={initialRef} onKeyDown={handleKeyDownEmail} placeholder='MrBeast@gmail.com' _placeholder={{ opacity: 1, color: 'gray.500' }} onChange={saveEmail}/>
          </FormControl>
            
            
          <Button size="lg" colorScheme="purple" type="submit" onClick={plusOne}>
            Get Early Access
          </Button>
          </Stack>
          </Center>

          <Center mt="12px" >
            <VStack spacing={4} mb="12px">
            <Link href="https://t.me/camarmstr" target="_blank"><Button>Share Feedback</Button></Link>
              <MasterComponent/>
              
            </VStack>
          </Center>
    </Container>
    </Box>
    </Route>




    <Route exact path="/outline">
      <Box bg="bg-surface" height="100%" overflowY="auto">
    <Container py={{ base: '0', md: '0' }}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '12', lg: '16' }}>
     
        <Center>

  
</Center>
      </Stack>
      <Flex direction="column" alignItems="center" justifyContent="center"  spacing="5">
      {/* <Center><Text fontSize='xl' as='b'  width={{base:"100%"}} mt='16px' mb='8px'>Topic - {topic}</Text></Center>
      <Center><Text fontSize='xl' as='b'  width={{base:"100%"}}  mb='8px'>Subtopic 1 - {subtopic1}</Text></Center>
      <Center><Text fontSize='xl' as='b'  width={{base:"100%"}}  mb='8px'>Subtopic 2 - {subtopic2}</Text></Center> */}
      <Center><Text fontSize='3xl' as='b'  width={{base:"100%"}} mt='8px' mb='32px'>Outline Topic - {title}</Text></Center>
    </Flex>
    {(callState === "loading") ? <Center mb='10'><Spinner size='xl'/></Center>: null}
    
    <Center>

    <Box width={{ base: "100%", md: "70%" }} >

      
        <Box borderWidth="1px" borderRadius="lg" p="4" mb="4">
          <Flex direction={{base:"column",md:"row"}} >
          <VStack alignItems="start" spacing={2}>
        <UnorderedList  as='b' fontSize="2xl">{renderOutline()}</UnorderedList>
      </VStack>


          </Flex>
        </Box>
      
    </Box>

    </Center>

    <Center>
      <VStack>
    {(callState === "loading") ? <Center mt='10' mb='10'><Spinner size='xl'/></Center>: null}
<Stack   direction={{base:"column",md:"row"}} spacing="6">
    <Center>
      
    <Button onClick={()=>{startOver()}} height="60px"  mx="2">
        <Text fontSize="xl">Start Over</Text>
      </Button>
      </Center>
      <Center>
    <Button onClick={()=>{continueText()}} disabled={(localStorage.getItem('displayTextPre') !== "[]") && (localStorage.getItem('displayTextPre') !== null)} height="60px"  mx="2" >
        <Text fontSize="xl">Get More Text</Text>
      </Button>
      </Center>
      <Center>
    <Button onClick={()=>{exportTextButton()}} height="60px"  mx="2" border="1px solid #B794F4">
        <Text fontSize="xl">Save Outline ✨</Text>
      </Button>
      </Center>
      <Center>
    {/* <Button onClick={()=>{handleButtonClick(topic)}} height="60px"  mx="2">
        <Text fontSize="xl">Gimme More Ideas</Text>
      </Button> */}
      </Center>
        <Center>
      </Center>
      </Stack>
      </VStack>
      </Center>
     <Center>
      
     <Stack width={{ base: "100%", md: "70%" }} mt='30px' direction={{base:"column",md:"row"}} >
     
          <FormControl>
                <Input border="2px solid gray" backgroundColor="white" color="black" variant='outlined' size='lg' ref={initialRef} onKeyDown={handleKeyDownEmail} placeholder='MrBeast@gmail.com' _placeholder={{ opacity: 1, color: 'gray.500' }} onChange={saveEmail}/>
          </FormControl>
            
            
          <Button size="lg" colorScheme="purple" type="submit" onClick={plusOne}>
            Get Early Access
          </Button>
          </Stack>
          </Center>

          <Center mt="12px" >
            <VStack spacing={4} mb="12px">
            <Link href="https://t.me/camarmstr" target="_blank"><Button>Share Feedback</Button></Link>
              <MasterComponent/>
              
            </VStack>
          </Center>
    </Container>
    </Box>
    </Route>
  


   
          {/* Developer Tag at Bottom */}
          <Route exact path="/about">
               {/* <Stack spacing={{ base: '8', md: '10' }} width="full" justify="center"> */}
          {/* <Stack spacing={{ base: '4', md: '6' }}>
    
            <Heading
            style={{ textAlign: "center" }}
              size={{
                base: 'lg',
                // md: 'lg',
              }}
            >
              Are You Ready to Make Better Content?
            </Heading>
            <Text
              align='center'
              fontSize={{
                base: 'lg',
                md: 'xl',
              }}
              color="muted"
            >
              Unlock endless creativity with Muse and never run out of ideas again! 
            
            </Text>
              
          </Stack> */}
          {/* <Stack direction={{ base: 'column', md: 'row' }} spacing="3">
    
          <FormControl>
                <Input border="2px solid gray" backgroundColor="white" color="black" variant='outlined' size='lg' ref={initialRef} placeholder='MrBeast@gmail.com' _placeholder={{ opacity: 1, color: 'gray.500' }} onChange={saveEmail}/>
          </FormControl>
            
            
          <Button size="lg" colorScheme="purple" type="submit" onClick={plusOne}>
            Get Early Access
          </Button>
        </Stack> */}
        {/* </Stack> */}

      {/* <Image
          width={{lg:"full", base:"60%"}}
          boxShadow='dark-lg' p='1' rounded='md'
          height={{
            base: 'auto',
            md: 'auto',
          }}
          objectFit="cover"
          alt='Creator Economy Tools'
          src="https://images.unsplash.com/photo-1613294326794-e7c74fe886e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        /> */}

              {/* <Box as="section" bg="bg-surface">
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        spacing={{
          base: '12',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '4',
            md: '5',
          }}
          align="center"
          textAlign="center"
        >
          <Stack spacing="3">
            <Text
              fontSize={{
                base: 'sm',
                md: 'md',
              }}
              fontWeight="semibold"
              color="accent"
            >
              Features
            </Text>
            <Heading
              size={{
                base: 'sm',
                md: 'md',
              }}
            >
              How Does Muse Work?
            </Heading>
          </Stack>
          <Text
            color="muted"
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            maxW="3xl"
          >
            We help you turbocharge your content creation by recommending new ideas, trending topics, and unexpected gems that your audience actually wants to see! 
          </Text>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3,
          }}
          columnGap={8}
          rowGap={{
            base: 10,
            md: 16,
          }}
        >
          {features.map((feature) => (
            <Stack
              key={feature.name}
              spacing={{
                base: '4',
                md: '5',
              }}
              align="center"
              textAlign="center"
            >
              <Square
                size={{
                  base: '10',
                  md: '12',
                }}
                bg="accent"
                color="inverted"
                borderRadius="lg"
              >
                <Icon
                  as={feature.icon}
                  boxSize={{
                    base: '5',
                    md: '6',
                  }}
                />
              </Square>
              <Stack
                spacing={{
                  base: '1',
                  md: '2',
                }}
              >
                <Text
                  fontSize={{
                    base: 'lg',
                    md: 'xl',
                  }}
                  fontWeight="medium"
                >
                  {feature.name}
                </Text>
                <Text color="muted">{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
  <Container
    py={{
      base: '8',
      md: '12',
    }}
  >
    <Stack
      spacing={{
        base: '6',
        md: '8',
      }}
    >
      <Stack
        spacing={{
          base: '0',
          md: '0',
        }}
      >
        <Stack spacing="3" align="center" textAlign="center">
          {/* <Text
            fontSize={{
              base: 'sm',
              md: 'md',
            }}
            color="accent"
            fontWeight="semibold"
          >
            We're hiring
          </Text> */}
          {/* <Stack
            spacing={{
              base: '4',
              md: '5',
            }}
          >
            <Heading
              size={{
                base: 'sm',
                md: 'md',
              }}
            >
              Meet our team
            </Heading> */} 
            {/* <Text
              fontSize={{
                base: 'lg',
                md: 'xl',
              }}
              color="muted"
            >
              Chupa chups pudding marzipan cake chocolate shortbread macaroon oat cake.
            </Text> */}
          {/* </Stack>
        </Stack> */}
        {/* <Stack
          direction={{
            base: 'column-reverse',
            md: 'row',
          }}
          spacing="3"
          justify="center"
        >
          <Button variant="secondary" size="lg">
            Contact us
          </Button>
          <Button variant="primary" size="lg">
            Join our team
          </Button>
        </Stack> */}
      {/* </Stack> */}
      
      {/* <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
        }}
        columnGap="8"
        rowGap={{
          base: '6',
          md: '8',
          lg: '16',
        }}
      > */}
      {/* <Center >
        {members.map((member) => (
          <Box key={member.name} bg="bg-surface" p="6" boxShadow="sm" borderRadius="md" width={{ base: "100%", md: "50%" }}>
            <Stack spacing="4" align="center" textAlign="center" >
              <Stack>
                <Stack
                  spacing={{
                    base: '4',
                    md: '5',
                  }}
                  align="center"
                >
                  <Avatar
                    src={member.image}
                    boxSize={{
                      base: '16',
                      md: '20',
                    }}
                  />
                  <Box >
                    <Text fontWeight="medium" fontSize="lg">
                      {member.name}
                    </Text>
                    <Text color="accent">{member.role}</Text>
                  </Box>
                </Stack>
                <Center>
                <Text width={{ base: "100%", md: "90%" }} color="muted">{member.description}</Text>
                </Center>
              </Stack>
              <HStack spacing="4" color="subtle">
                
                {[
                 { icon: FaGithub, link: "https://github.com/camarmstr" },
                 { icon: FaLinkedin, link: "https://linkedin.com/in/cameron-armstrong14" },
                 { icon: FaTwitter, link: "https://twitter.com/frozenfire42" }
                ].map(({ icon, link }, id) => (
                  <Link href={link} key={id}>
                    <Icon as={icon} boxSize="5" />
                  </Link>
                ))}
              </HStack>
            </Stack>
          </Box>
        ))}
       </Center>
    </Stack>
    
  </Container> */}

  {/*     
    <Text
              fontSize={{
                base: 'lg',
                md: 'xl',
              }}
              color="muted"
            >
             
              You'll also be first in line to get access to Creator payments, monetized fan polls, and audience hype and growth tools.
            Sign up today to get ideas tailored specifically for you and your niche! 
            </Text> */}
            </Route>
          </Switch>
          
          </Box>
          
</Router>

</ChakraProvider>
  );
}

export default App;
